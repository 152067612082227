<template>
  <q-table
    class="table-content"
    :rows-per-page-options="[5]"
    :title="titleTable"
    style="box-shadow: none"
    color="default-pink"
    :rows="list"
    :columns="columns"
    :filter="filter"
    row-key="acao"
    virtual-scroll
  >
    <template v-slot:top-left>
      <div class="table__title">{{ titleTable }}</div>
    </template>

    <template v-slot:top-right>
      <q-btn flat rounded color="default-pink" size="12px" v-if="showFilter">
        <q-img width="20px" :src="filterimg" spinner-color="white" />
        <q-menu>
          <q-list style="min-width: 100px">
            <q-item clickable v-close-popup>
              <q-item-section @click="$emit('filter', 0)">
                <p>Inativos</p>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('filter', 1)">
              <q-item-section>
                <p>Ativos</p>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('filter', 2)">
              <q-item-section>
                <p>Todos</p>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-input
        class="col-3 input-search q-ma-sm"
        bg-color="white"
        color="default-pink"
        dense
        type="text"
        placeholder="Pesquisar"
        outlined
        rounded
        v-model="filter"
      >
        <template v-slot:prepend>
          <q-icon name="search" color="default-pink" />
        </template>
      </q-input>
    </template>

    <template v-slot:body-cell-acao="props">
      <q-td :props="props">
        <slot name="body" v-bind:props="props"></slot>

        <!-- <div>
          <q-btn
            dense
            round
            color="default-pink"
            title="Visualizar"
            v-show="addView"
            flat
            @click="$emit('view', props.row)"
            icon="visibility"
          />
          <q-btn
            dense
            round
            color="default-pink"
            title="Editar"
            v-show="addCoin"
            flat
            @click="$emit('addCoin', props.row)"
          >
            <q-img
              :src="coins"
              class="button-icon q-mx-sm"
              color="white"
              width="20px"
              height="auto"
            />
          </q-btn>
          <q-btn
            dense
            round
            color="default-pink"
            title="Editar"
            v-show="addEdit"
            flat
            @click="$emit('edit', props.row)"
            icon="o_edit_note"
          />
          <q-btn
            dense
            round
            color="default-pink"
            title="Remover"
            v-show="addRemove"
            flat
            @click="$emit('disabled', props.row)"
            icon="o_delete_outline"
          />
        </div> -->
      </q-td>
    </template>
  </q-table>
</template>

<script>
import filterimg from "@/assets/icons/filter.svg";

import coins from "@/assets/icons/coins.svg";

export default {
  name: "TableDynamic",
  props: {
    list: Array,
    columns: Array,
    showFilter: {
      type: Boolean,
      default: false,
    },
    titleTable: {
      type: String,
      default: "",
    },
    addEdit: {
      type: Boolean,
      default: true,
    },
    addCoin: {
      type: Boolean,
      default: false,
    },
    addRemove: {
      type: Boolean,
      default: true,
    },
    addView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["disabled", "edit", "view", "promotion", "addCoin", "filter"],
  data() {
    return {
      filterimg,

      filter: "",
      coins,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-img {
  overflow: hidden;
  border-radius: 50px;
  height: 50px;
  width: 50px;
}

.input-search {
  background: #ffffff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
}

.table__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--q-primary);
}
</style>
