<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="home" :query="{}">
      <DefaultBtn
        v-show="validateActionsPage('groups-add')"
        titleButton="Novo Grupo"
        icon="add"
        route="new-group"
      />
    </Mural>

    <main class="lesson-content q-my-lg">
      <div class="content-table row flex q-pa-md">
        <div class="column col-12">
          <Table
            class="full-height"
            :list="groups"
            :columns="columnsTable"
            :addEdit="validateActionsPage('groups-edit')"
            :addCoin="validateActionsPage('add-points-manual')"
            :addRemove="validateActionsPage('groups-delete')"
            @addCoin="(group) => addCoinGroup(group)"
            @disabled="(group) => disabled(group)"
            @edit="(group) => edit(group)"
          ><template v-slot:body="props">
              <div>
                <q-btn
                  v-if="validateActionsPage('groups-edit')"
                  dense
                  round
                  color="default-pink"
                  title="Editar"
                  flat
                  @click="() => edit( props.props.row)"
                  icon="o_edit_note"
                />
                <q-btn
                  v-if="validateActionsPage('add-points-manual')"
                  dense
                  round
                  color="default-pink"
                  title="Editar"
                  flat
                  @click="() => addCoinGroup( props.props.row)"
                >
                <coinsImg 
                  color="var(--q-secundary)"
                />
                  <!-- <q-img
                    :src="coins"
                    class="button-icon q-mx-sm"
                    color="white"
                    width="20px"
                    height="auto"
                  /> -->
                </q-btn>
                <q-btn
                  v-if="validateActionsPage('groups-edit')"
                  dense
                  round
                  color="default-pink"
                  title="Remover"
                  flat
                  @click="() => disabled( props.props.row)"
                  icon="o_delete_outline"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>
    </main>

    <DialogPoints 
      v-if="showGroup"
      :showDialog="showGroup" 
      :group="groupSelected"
      @closeDialog="() => showGroup = false"
      @saveDialog="(value) => addCoinsGroup(value)"
    />

    <q-inner-loading
      :showing="loadingPage"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
//----IMAGES
import coinsImg from '@/assets/icons/custom/Coins.vue';

import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";
import DialogPoints from "@/components/admin/dialogs/DialogPoints.vue";

import CanUserAccessClass from "@/utils/CanUserAccessClass";

import GroupServices from "@/services/GroupServices";

import PointServices from "@/services/PointServices";


import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";

export default {
  name: "Groups",
  components: {
    Mural,
    DefaultBtn,
    Table,
    LoadingIcon,
    DialogPoints,
    coinsImg
  },
  setup() {
    const pointServices = new PointServices();

    let groupServices = new GroupServices();
    let breadcrumbs = ref(["Grupos"]);
    let groups = ref([]);
    let $q = new useQuasar();
    let router = new useRouter();
    let loadingPage = ref(false);

    let canUserAccessClass = new CanUserAccessClass();

    let columnsTable = ref([
      { name: "id", align: "left", label: "ID", field: "id", sortable: false },
      {
        name: "group_name",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.group_name,
        sortable: true,
      },
      {
        name: "company_name",
        align: "left",
        label: "Empresa",
        field: "company_name",
        sortable: false,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);
    let groupSelected = ref({});
    let showGroup = ref(false);
    async function disabled(group) {
      $q.notify({
        message: `Deseja remover a empresa ${group.group_name}`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              await removeGroup(group.id);
            },
          },
        ],
      });
    }

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function edit(group) {
      router.push({ name: "new-group", query: { groupId: group.id } });
    }

    async function removeGroup(groupId) {
      loadingPage.value = true;
      await groupServices.disabled(groupId).catch((error) => {
        alertErro(error.response.data.toString());
        loadingPage.value = false;
      });

      groups.value = groups.value.filter((group) => {
        return group.id != groupId;
      });
      loadingPage.value = false;
    }

    async function getAllGroups() {
      loadingPage.value = true;
      let data = await groupServices.listGroups();
      groups.value = data.data;
      
      loadingPage.value = false;
    }

    async function addCoinGroup(group) {
      groupSelected.value = group;
      showGroup.value = true;
    }

    async function addCoinsGroup(form){
      let EVENT_ID = 7;
      let EVENT_TYPE = "C";
      let TYPE_ID = "group";

      let formDataAddPoint = new FormData();

      formDataAddPoint.append("EventId", EVENT_ID);
      formDataAddPoint.append("EventType", EVENT_TYPE);
      formDataAddPoint.append("IdType", TYPE_ID);
      formDataAddPoint.append("GroupId", form.group);
      formDataAddPoint.append("Value", form.value);
      formDataAddPoint.append("Observation", form.description);

      await pointServices.addPoint(formDataAddPoint)
        .then((response) => {
          alertErro("Moedas adicionado com sucesso", "green-9");
        }).catch((err) => {
          alertErro("Erro ao adicionar moedas");
        });
        
      showGroup.value = false;
    }

    function alertErro(message, color="red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    onMounted(() => {
      getAllGroups();
    });

    return {
      //----Images
      

      breadcrumbs,
      groups,
      loadingPage,
      columnsTable,
      disabled,
      edit,
      validateActionsPage,
      addCoinGroup,
      groupSelected,
      showGroup,
      addCoinsGroup
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-card {
  width: 100%;
  height: 206px;
  background: #f7f7f7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.content {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #9c9c9c;

  .bold_number {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000 !important;
  }
}

.image-groups {
  border-radius: 20px;
}

.card-btn {
  box-shadow: 3px 10px 15px #00000033;
  width: 100px;
}
</style>
